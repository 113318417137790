(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/users/assets/javascripts/services/delete-editor.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/users/assets/javascripts/services/delete-editor.js');
'use strict';

const {
  jupiter
} = svs.core;
const deleteEditor = (editorId, callback) => {
  jupiter.delete("/retailer/1/marketplace/editors/".concat(editorId), () => {
    callback();
  }, callback);
};
setGlobal('svs.components.marketplaceRetailAdminData.users.services.deleteEditor', deleteEditor);

 })(window);