(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/users/assets/javascripts/services/load-terminal-users.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/users/assets/javascripts/services/load-terminal-users.js');

'use strict';

const {
  jupiter
} = svs.core;
const loadTerminalUsers = callback => {
  jupiter.get('/retailer/1/userlist', result => {
    callback(null, result.users);
  }, err => {
    callback(err.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.users.services.loadTerminalUsers', loadTerminalUsers);

 })(window);