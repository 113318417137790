(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/users/assets/javascripts/services/update-user.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/users/assets/javascripts/services/update-user.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  jupiter
} = svs.core;
const roleMapping = {
  GAME_CREATOR: 10,
  GAME_ADMIN: 20,
  ADMIN: 30,
  OWNER: 40
};
const relationMapping = {
  employee: 1,
  external: 2
};
const updateUser = (user, callback) => {
  const {
    groupId,
    editorId,
    userId
  } = user;
  if (!editorId) {
    return createEditor(groupId, userId, user, callback);
  }
  updateEditor(editorId, user, callback);
};
function createEditor(groupId, userId, user, callback) {
  jupiter.post({
    path: "/retailer/1/marketplace/editors",
    data: _objectSpread({
      groupId,
      editorUserId: userId
    }, mapEditor(user))
  }, _ref => {
    let {
      CreateEditorReply
    } = _ref;
    return callback(null, CreateEditorReply.editorId);
  }, callback);
}
function updateEditor(editorId, user, callback) {
  jupiter.put({
    path: "/retailer/1/marketplace/editors/".concat(editorId),
    data: mapEditor(user)
  }, () => callback(), callback);
}
function mapEditor(_ref2) {
  let {
    role,
    relation,
    email,
    name
  } = _ref2;
  return {
    editorRole: roleMapping[role],
    editorType: relationMapping[relation],
    editorEmail: email,
    editorName: name
  };
}
setGlobal('svs.components.marketplaceRetailAdminData.users.services.updateUser', updateUser);

 })(window);